export class Transactions {
  /** Fields/columns that are important and thus should be preferred (in given order, case-sensitive). */
  static VITAL_FIELDS = [
    'created',
    'token',
    'site',
    'smartid',
    'exactid',
    'fraudscore_raw',
    'fraudscore_rulematches'
  ];
}
