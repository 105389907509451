/**
 * Response of Device.Ident login.
 */
interface ApiLoginResponse {
  username: string;
  password: string;
  isGroup?: boolean;
  sites?: string[];
}

class AuthStorage {
  /**
   * Authenticate a user. (Save a token string in Local Storage.)
   */
  static authenticate(token: string, payload: ApiLoginResponse) {
    localStorage.setItem('token', token);
    localStorage.setItem('accountId', payload.username);
    localStorage.setItem('apiKey', payload.password);
  }

  /**
   * Check if a user is authenticated (by checking if a token is saved in Local Storage).
   */
  static isAuthenticated() {
    return !!localStorage.getItem('token');
  }

  /**
   * De-authenticates a user (by removing a token from Local Storage).
   */
  static deAuthenticate() {
    localStorage.removeItem('token');
    localStorage.removeItem('isGroup');
    localStorage.removeItem('sites');
    localStorage.removeItem('accountId');
    localStorage.removeItem('apiKey');
  }

  static getSites(): string[] {
    return JSON.parse(localStorage['sites'] || null);
  }

  static isGroup() {
    return localStorage.getItem('isGroup') === 'true';
  }

  static saveResponse(response: ApiLoginResponse) {
    localStorage.setItem('isGroup', response.isGroup!.toString());
    localStorage.setItem('sites', JSON.stringify(response.sites));
  }

  static getToken() {
    return localStorage.getItem('token');
  }

  /**
   * @returns Account ID, else empty string
   */
  static getAccountId() {
    return localStorage.getItem('accountId') || '';
  }

  /**
   * @returns API key, else empty string
   */
  static getApiKey() {
    return localStorage.getItem('apiKey') || '';
  }
}

export default AuthStorage;
