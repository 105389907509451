import { ParseResult } from 'papaparse';

interface EvidenceItem {
  token: string;
  site: string;
  fraud_level: string;
  // There might be more with unknown name
}

export class Evidences {
  name: string;

  /** Info every evidence must have. */
  private fields = ['token', 'site', 'fraud_level'];
  /** Additional info fields with unknown names. */
  private addFields: string[];

  items: EvidenceItem[];

  /**
   * @param file File the parsed evidences originated from
   * @param allowedSites Valid values for the 'site' column
   * @throws Error if it couldn't be correctly created
   */
  constructor(parsed: ParseResult, file: File, allowedSites: string[]) {
    this.name = file.name;

    this.addFields = parsed.meta.fields.filter(f => !this.fields.includes(f));

    this.items = parsed.data as EvidenceItem[];
    this.checkValid(parsed, allowedSites);
  }

  /** @returns Evidence item formatted as object in Device.Ident-specific format */
  asData(item: EvidenceItem): object {
    return Object.assign(
      { status: item.fraud_level },
      { token: item.token },
      {
        customArgs: btoa(
          JSON.stringify(
            this.addFields.reduce((agg, f) => ({ [f]: item[f], ...agg }), {})
          )
        )
      }
    );
  }

  /** @throws Error if the parsed CSV is invalid */
  private checkValid(parsed: ParseResult, allowedSites: string[]) {
    if (this.addFields.some(f => f === '')) {
      throw new Error('At least one column header is empty.');
    }

    if (parsed.errors.length > 0) {
      throw new Error(
        parsed.errors
          .slice(0, 3)
          .map(e => `${e.message} (row ${e.row + 1})`)
          .join('; ')
      );
    }

    const missingFields = this.fields.filter(
      f => !parsed.meta.fields.includes(f)
    );
    if (missingFields.length > 0) {
      throw new Error(
        `Required column(s) are missing: ${missingFields.join(' and ')}`
      );
    }

    if (this.addFields.some(f => !/^[\w-.]+$/.test(f))) {
      throw new Error(
        'Custom column(s) are not correctly formatted — should only be alphanumeric.'
      );
    }

    if (this.addFields.length > 3) {
      throw new Error(
        'There are too many custom column(s) — not more than 3 allowed.'
      );
    }

    if (parsed.data.length === 0) {
      throw new Error('There is no data.');
    }

    for (let i = 0; i < parsed.data.length; i++) {
      const row = parsed.data[i];
      if (!/^\S.*\S$/.test(row.token)) {
        throw new Error(`Token '${row.token}' in row ${i + 2} is invalid.`);
      }
      if (!allowedSites.includes(row.site)) {
        throw new Error(`Site '${row.site}' in row ${i + 2} is not allowed.`);
      }
      if (!/^(NoFraud|Fraud|Suspicion)$/.test(row.fraud_level)) {
        throw new Error(
          `Fraud level '${row.fraud_level}' in row ${i + 2} is unknown.`
        );
      }
    }
  }
}
