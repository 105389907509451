import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from '../common/Icon';
import AuthStorage from '../common/AuthStorage';

class Sidebar extends Component {
  deAuthenticate = () => {
    AuthStorage.deAuthenticate();
    window.location.href = '/login';
  };
  render() {
    return (
      <nav>
        <div className="navigation">
          <div className="logo-icon">
            <img src="assets/logo.png" alt="logo" height="32" />
          </div>
          <NavLink to="/" activeClassName="active">
            <Icon name="ct-deviceInfo" className="icon" />
          </NavLink>
          <a onClick={this.deAuthenticate}>
            <Icon name="logout" className="icon" />
          </a>
        </div>
      </nav>
    );
  }
}

export default Sidebar;
