import './App.scss';

import React, { Component } from 'react';
import { Route } from 'react-router';

import AuthStorage from './common/AuthStorage';
import { PrivateRoute } from './common/PrivateRoute';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import EvidenceUpload from './pages/EvidenceUpload';
import Login from './pages/Login';
import TransactionDownload from './pages/TransactionDownload';

class App extends Component<{}, { isLoggedIn: boolean }> {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = { isLoggedIn: true };
  }
  render() {
    const isLoggedIn = AuthStorage.isAuthenticated();
    return (
      <div>
        {isLoggedIn && <Sidebar />}
        {isLoggedIn && <Header />}
        <PrivateRoute exact path="/" component={EvidenceUpload} />
        <PrivateRoute path="/evidence-upload" component={EvidenceUpload} />
        <PrivateRoute
          path="/transaction-download"
          component={TransactionDownload}
        />
        <Route path="/login" component={Login} />
      </div>
    );
  }
}

export default App;
