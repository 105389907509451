import React, { Component } from 'react';

import AuthStorage from '../common/AuthStorage';
import { Icon } from '../common/Icon';
import Download from '../components/Download';

class TransactionDownload extends Component {
  render() {
    const accountId = AuthStorage.getAccountId();
    const isGroup = AuthStorage.isGroup();
    const sites = AuthStorage.getSites();
    return (
      <main>
        <div className="sidebar">
          <Icon name="ct-customFingerprint" className="icon" />
        </div>
        <div className="content">
          <h3>Transaction Download</h3>
          <div className="content__component">
            <p>Download specific data…</p>
            <Download accountId={accountId} isGroup={isGroup} members={sites} />
          </div>
        </div>
      </main>
    );
  }
}

export default TransactionDownload;
