import './Login.scss';

import React, { Component } from 'react';
import { Redirect } from 'react-router';

import AuthStorage from '../common/AuthStorage';
import HttpClient from '../common/HttpClient';
import diLogo from './di.png';
import { Icon } from '../common/Icon';

class Login extends Component<LoginProps, LoginState> {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      siteId: '',
      token: '',
      history: '',
      error: ''
    };

    this.validateForm = this.validateForm.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (AuthStorage.isAuthenticated()) {
      this.props.history.push('/'); // redirect if you're already logged in
    }
  }

  validateForm() {
    return this.state.siteId.length > 0 && this.state.token.length > 0;
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ ...this.state, [name]: value });
  };

  handleClick = event => {
    event.preventDefault();
    const self = this;

    const payload = {
      username: this.state.siteId,
      password: this.state.token
    };

    const headerconfig = btoa(payload.username + ':' + payload.password);

    HttpClient('account/login', {
      headers: { Authorization: 'Basic ' + headerconfig }
    })
      .then(function(response) {
        AuthStorage.authenticate(headerconfig, payload);
        AuthStorage.saveResponse(response.data);
        self.props.history.push('/');
      })
      .catch(error => {
        this.setState({ error: 'Invalid site ID or token' });
      });
  };

  render() {
    const { from } = { from: { pathname: '/' } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to={from} />;
    }

    return (
      <div className="login">
        <div className="login-form">
          <img src={diLogo} alt="di Logo" className="frida-logo" />
          {this.state.error && (
            <div className="alert">
              <Icon name="hint-attention" className="alert__icon" />
              Invalid Account ID or API Key
            </div>
          )}
          <form onSubmit={this.handleClick}>
            <fieldset className="frida-input">
              <input
                type="text"
                value={this.state.siteId}
                required
                name="siteId"
                id="siteId"
                onChange={this.handleChange}
              />
              <hr />
              <label>Account ID</label>
            </fieldset>
            <fieldset className="frida-input">
              <input
                type="password"
                value={this.state.token}
                required
                id="token"
                name="token"
                onChange={this.handleChange}
              />
              <hr />
              <label>API Key</label>
            </fieldset>

            <button
              className="button lg full"
              role="button"
              disabled={!this.validateForm}
            >
              Login
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;

interface LoginProps {
  history: any;
}

interface LoginState {
  siteId: string;
  token: string;
  redirectToReferrer: boolean;
  history: string;
  error: string;
}
