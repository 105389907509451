import './Upload.scss';

import { boundMethod } from 'autobind-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import { ParseError, ParseResult } from 'papaparse';
import React, { ChangeEvent, Component } from 'react';

import { Account } from '../common/Account';
import { Evidences } from '../common/Evidences';
import HttpClient from '../common/HttpClient';
import { Icon } from '../common/Icon';
import { Util } from '../common/Util';
import chunk from 'lodash.chunk';

class Upload extends Component<UploadProps, UploadState> {
  constructor(props: UploadProps) {
    super(props);
    this.state = {
      msg: {},
      loading: false,
      success: false,
      showExample: false
    };
  }

  render() {
    return (
      <div className="Upload">
        {this.state.success && (
          <React.Fragment>
            <div className="card-content">
              {this.state.msg.type === 'info' && (
                <div className="alert success lg">
                  <div className="alert__heading">
                    <Icon name="hint-check" className="alert__icon" />
                    {this.state.msg.text}
                  </div>
                </div>
              )}
            </div>
            <div className="card-footer">
              <button
                onClick={this.handleFilenameButton}
                className="upload-file button"
              >
                <Icon name="upload" className="button-icon" />
                Upload another Evidence
              </button>
            </div>
          </React.Fragment>
        )}

        {!this.state.success && (
          <React.Fragment>
            <div className="card-content">
              <h4>Select file</h4>
              <p className="dark">
                Select a file to upload. Only files with the following
                extensions are allowed: <samp>.csv</samp>{' '}
                <em>
                  (
                  <a onClick={this.showExample} className="show-example">
                    {!this.state.showExample ? 'Show' : 'Hide'} example
                  </a>
                  )
                </em>
              </p>
              {this.state.showExample && (
                <pre>
                  <strong>"token","site","fraud_level","custom_args"</strong>
                  <br />
                  "a1B2c3","site-1","Fraud","custom value"
                  <br />
                  "b1B2c4","site-2","NoFraud",
                  <br />
                  "c1B2c5","site-3","Suspicion","a ""quoted"" value"
                </pre>
              )}

              <label
                className={`select-file button ${!!this.state.evidences &&
                  'disabled'}`}
              >
                <input
                  type="file"
                  accept=".csv,text/csv"
                  onChange={this.handleFileInputChange}
                  disabled={!!this.state.evidences}
                />
                <Icon name="upload" className="button-icon" />
                <span>Select File</span>
              </label>

              {this.state.evidences && (
                <div className="file">
                  <div className="label">Selected file: </div>
                  <div className="fileName">
                    {this.state.evidences.name}
                    <div
                      className="close"
                      onClick={this.handleFilenameButton}
                    />
                  </div>
                </div>
              )}

              {this.state.msg.type === 'warn' && (
                <div className="alert">
                  <div className="alert__heading">
                    <Icon name="hint-attention" className="alert__icon" />
                    An error occurred with the selected file
                  </div>
                  <div className="alert__content">{this.state.msg.text}</div>
                </div>
              )}
              {this.state.msg.type === 'info' && (
                <div className="alert success">
                  <div className="alert__heading">
                    <Icon name="hint-check" className="alert__icon" />
                    {this.state.msg.text}
                  </div>
                </div>
              )}
            </div>

            <div className="card-footer">
              <button
                className="upload-file button"
                onClick={this.handleUploadButton}
                disabled={!this.state.evidences || this.state.loading}
              >
                <Icon name="upload" className="button-icon" />
                {this.state.loading && <div className="loader">Loading...</div>}
                {!this.state.loading && <div>Upload CSV File</div>}
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  /** Parses the chosen CSV file. */
  @boundMethod
  private handleFileInputChange(event: ChangeEvent<HTMLInputElement>) {
    const onError = (e: ParseError) =>
      this.setState({
        msg: { text: `${e.message} (row ${e.row + 1})`, type: 'warn' }
      });
    Util.parseSelectedCsv(event, this.onParseComplete, onError);
  }

  /** Parses the chosen CSV file. */
  @boundMethod
  private showExample() {
    this.setState({ showExample: !this.state.showExample });
  }

  /** Ensures the CSV is valid. */
  @boundMethod
  private onParseComplete(result: ParseResult, file: File) {
    let evidences: any;
    let msg: any;

    try {
      evidences = new Evidences(result, file, this.props.members);
      msg = {
        text: `${evidences.items.length} evidences are ready to be uploaded.`,
        type: 'info'
      };
    } catch (e) {
      msg = { text: e.message, type: 'warn' };
    }
    this.setState({ evidences, msg });
  }

  /** Clears the currently selected file. */
  private handleFilenameButton() {
    // Workaround: Reload page because of Evidences' nested state
    // Also see https://stackoverflow.com/a/43041334
    window.location.reload();
  }

  /** Uploads the parsed and validated CSV data. */
  @boundMethod
  private handleUploadButton() {
    this.setState({ loading: true });
    if (!this.state.evidences) return;

    let accountId = this.props.accountId;

    let evidences = this.state.evidences.items.map(i => {
      return this.state.evidences!.asData(i);
    });

    let batches = chunk(evidences, 1000);

    let job = batches.reduce((prev, evidences) => {
      return prev.then(_ =>
        HttpClient.put(`evidences/bySite/${accountId}`, evidences)
      );
    }, Promise.resolve<AxiosResponse<any>>((null as unknown) as AxiosResponse<any>));

    job
      .then(() => {
        this.setState({
          msg: {
            text: 'All evidences have been uploaded successfully.',
            type: 'info'
          },
          loading: false,
          success: true
        });
      })
      .catch((e: AxiosError) => {
        const msg =
          'Upload failed for some evidences: ' +
          (e.response ? e.response.data.message : e.message);
        this.setState({
          msg: { text: msg, type: 'warn' },
          loading: false,
          success: false
        });
      });
  }
}

export default Upload;

interface UploadProps extends Account {}

interface UploadState {
  /** Uploaded rows, grouped by columns. */
  evidences?: Evidences;
  /** Message about the upload status (if `type` is defined). */
  msg: {
    text?: string;
    type?: 'info' | 'warn';
  };
  loading?: boolean;
  success?: boolean;
  showExample?: boolean;
}
