import React, { Component } from 'react';
import ReactSVG  from 'react-svg';

export class Icon extends Component<IconProps> {
  constructor(props: IconProps) {
    super(props);
  }

  render() {
    return (
      <ReactSVG
        src={`assets/${this.props.name}.svg`}
        evalScripts="always"
        renumerateIRIElements={false}
        svgClassName={this.props.className}
        className={this.props.className}
      />
    );
  }
}

interface IconProps {
  /** Name of the SVG asset. */
  name: string;
  className?: string;
}
