import moment from 'moment';
import { parse, ParseError, ParseResult, unparse } from 'papaparse';
import { ChangeEvent } from 'react';

export class Util {
  /**
   * Converts the object into URL search parameters as required e.g. for Axios' PUT request.
   * @see https://github.com/axios/axios#using-applicationx-www-form-urlencoded-format
   */
  static toUrlSearchParams(o: object) {
    const result = new URLSearchParams();
    Object.keys(o).forEach(k => result.append(k, o[k]));
    return result;
  }

  /**
   * @param event Input change event to extract the file from
   * @param onComplete Triggered once the parsing completed
   * @param onError Triggered if an error occurred
   */
  static parseSelectedCsv(
    event: ChangeEvent<HTMLInputElement>,
    onComplete: (r: ParseResult, f: File) => void,
    onError?: (e: ParseError) => void
  ) {
    const file = (event.target.files || [])[0];
    parse(file, {
      download: true,
      header: true,
      delimiter: '', // Auto-detect
      skipEmptyLines: true,
      complete: onComplete,
      error: onError
    });
  }

  /**
   * @param firstFields Columns of the `data` that should come first
   * @returns CSV of provided data as encoded URI component
   */
  static csvUriOf(data: object[], firstFields: string[] = []) {
    const availFields = Object.keys(data[0]);
    const prefFields = firstFields.filter(f => availFields.includes(f));
    const fields = Array.from(new Set([...prefFields, ...availFields.sort()]));
    const csv = unparse({ fields, data });
    return 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
  }

  /**
   * @param date (Undefined in, undefined out)
   * @param adjustDays Days to add (or negative to substract)
   * @returns Local date formatted as "YYYYMMDD" string
   */
  static stringOfDate(date?: Date, adjustDays = 0) {
    return date
      ? moment(date)
          .add(adjustDays, 'day')
          .format('YYYYMMDD')
      : undefined;
  }
}
