import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.scss';

class Header extends Component {
  render() {
    return (
      <header>
        <div />
        <div className="header__navigation">
          <NavLink to="/" exact activeClassName="active">
            <span className="label">Evidence Upload</span>
          </NavLink>
          <NavLink exact to="/transaction-download" activeClassName="active">
            <span className="label">Transaction Download</span>
          </NavLink>
        </div>
      </header>
    );
  }
}

export default Header;
