import axios from 'axios';

import AuthStorage from './AuthStorage';

// Create instance with default options
const HttpClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Set the AUTH token (if present) for any request
HttpClient.interceptors.request.use(config => {
  const token = AuthStorage.getToken();
  if (token) {
    config.headers.Authorization = `Basic ${token}`;
  }
  return config;
});

export default HttpClient;
