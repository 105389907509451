import 'react-datepicker/dist/react-datepicker.css';
import './Download.scss';

import { boundMethod } from 'autobind-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import React, { ChangeEvent, Component, createRef, RefObject } from 'react';
import DatePicker from 'react-datepicker';

import { Account } from '../common/Account';
import HttpClient from '../common/HttpClient';
import { Icon } from '../common/Icon';
import { Transactions } from '../common/Transactions';
import { Util } from '../common/Util';

class CustomInput extends Component<{ label: string }> {
  render() {
    const inputProps = this.props;
    const label = this.props.label;
    return (
      <fieldset className="frida-input">
        <input {...inputProps} required />
        <hr />
        <label>{label}</label>
      </fieldset>
    );
  }
}

class Download extends Component<DownloadProps, DownloadState> {
  /**
   * Workaround required to trigger download of custom CSV file.
   * @see https://stackoverflow.com/a/18678698
   */
  private downloadLinkRef: RefObject<HTMLAnchorElement>;

  constructor(props: DownloadProps) {
    super(props);
    this.state = {
      querySites: new Set(props.members),
      queryDate: {
        from: moment()
          .subtract(1, 'day')
          .toDate(),
        to: new Date()
      }
    };
    this.downloadLinkRef = createRef();
  }

  render() {
    return (
      <div className="Download">
        <div className="card-content">
          <div className="stepper">
            <div className="step">
              <div className="step__label">1</div>
              <div className="step__content">
                <div className="step__title">Select site</div>
                <div className="step__description">
                  Select site from which you want to export transaction data.
                </div>

                <div className="step__component checkboxes">
                  {this.props.members.map(m => (
                    <div className="ckbx" key={m}>
                      <input
                        type="checkbox"
                        id={m}
                        value={m}
                        disabled={this.props.members.length === 1}
                        checked={this.state.querySites.has(m)}
                        onChange={this.handleCheckboxInputChange}
                      />
                      <label htmlFor={m}>{m}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="step">
              <div className="step__label">2</div>
              <div className="step__content">
                <div className="step__title">Specify date range</div>
                <div className="step__description">
                  Define a period in which you want to check transactions.
                </div>

                <div className="step__component dates">
                  <DatePicker
                    minDate={moment()
                      .subtract(2, 'month')
                      .toDate()}
                    maxDate={this.state.queryDate.to || new Date()}
                    customInput={<CustomInput label="from" />}
                    dateFormat="dd.MM.yyyy"
                    selected={this.state.queryDate.from}
                    onChange={d => this.handleDateChange('from', d)}
                    showMonthDropdown
                    showYearDropdown
                  />

                  <DatePicker
                    minDate={
                      this.state.queryDate.from ||
                      moment()
                        .subtract(2, 'month')
                        .toDate()
                    }
                    maxDate={new Date()}
                    customInput={<CustomInput label="to" />}
                    selected={this.state.queryDate.to}
                    dateFormat="dd.MM.yyyy"
                    onChange={d => this.handleDateChange('to', d)}
                    showMonthDropdown
                    showYearDropdown
                  />
                </div>
              </div>
            </div>
          </div>
          {this.state.msgWarn && (
            <div className="alert">
              <div className="alert__heading">
                <Icon name="hint-attention" className="alert__icon" />
                An error occurred with the selected file
              </div>
              <div className="alert__content">{this.state.msgWarn}</div>
            </div>
          )}
        </div>

        <div className="card-footer">
          <a hidden ref={this.downloadLinkRef} />
          <button
            className="upload-file button"
            onClick={this.handleDownloadButton}
            disabled={!this.state.querySites.size}
          >
            <Icon name="download" className="button-icon" />
            <span>Download CSV File</span>
          </button>
        </div>
      </div>
    );
  }

  @boundMethod
  private handleCheckboxInputChange(event: ChangeEvent<HTMLInputElement>) {
    const querySites = this.state.querySites;
    event.target.checked
      ? querySites.add(event.target.value)
      : querySites.delete(event.target.value);
    this.setState({ querySites });
  }

  @boundMethod
  private handleDateChange(type: 'from' | 'to', date: Date | null) {
    const queryDate = { ...this.state.queryDate, [type]: date };
    this.setState({ queryDate });
  }

  @boundMethod
  private handleDownloadButton() {
    // Query all sites separately or by group if none is left out
    let type = 'bySite';
    let querySites = Array.from(this.state.querySites);
    if (
      this.props.isGroup &&
      this.state.querySites.size === this.props.members.length
    ) {
      type = 'byGroup';
      querySites = [this.props.accountId];
    }

    Promise.all(
      querySites.map(s =>
        HttpClient.get(`transactions/${type}/${s}`, {
          params: {
            from: Util.stringOfDate(this.state.queryDate.from),
            until: Util.stringOfDate(this.state.queryDate.to, 1), // Pretend API is inclusive
            include: 'all'
          }
        })
      )
    )
      .then((rs: AxiosResponse<{ transactions: any[] }>[]) => {
        const data = rs.flatMap(r => r.data.transactions);
        const ref = this.downloadLinkRef.current!;
        ref.href = Util.csvUriOf(data, Transactions.VITAL_FIELDS); // File to download
        ref.download = this.formatFilename(this.state.queryDate, querySites); // Suggested filename
        ref.click(); // Start download
        this.setState({ msgWarn: undefined });
      })
      .catch((e: AxiosError) => {
        const msgWarn = e.response ? e.response.data.message : e.message;
        this.setState({ msgWarn });
      });
  }

  private formatFilename(date: { from: Date; to: Date }, sites: string[]) {
    const dateFrom = moment(date.from).format('YYYY-MM-DD');
    const dateTo = moment(date.to).format('YYYY-MM-DD');
    const fDate =
      dateFrom !== dateTo ? `from ${dateFrom} to ${dateTo}` : `on ${dateTo}`;
    const fSites = sites.join(', ');
    return `Transactions ${fDate} for ${fSites}.csv`;
  }
}

export default Download;

interface DownloadProps extends Account {}

interface DownloadState {
  /** Sites to download transactions from. */
  querySites: Set<string>;
  /** In which time range? */
  queryDate: { from: Date; to: Date };
  /** Message about the download if it failed, else `undefined`. */
  msgWarn?: string;
}
